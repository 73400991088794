
.user-box {
    background-image: url('../../assets/home-content-bg.png');
    background-size: 100% 7215px;
    background-repeat: repeat;
}

.user-content {
    color: #303233;
    font-size: 24px;
    padding: 260px 360px 300px 360px;
    line-height: 46px;
    font-weight: bold;
}

.user-protocol-title {
    width: 607px;
    height: 93px;
    background-image: url('../../assets/user-protocol-text.png');
    background-size: 607px 93px;
    background-repeat: repeat;
    margin-bottom: 60px;
}