.header {
    height: 1080px;
    background-image: url('../../assets/header-bg.png');
    background-size: 100% 1080px;
    background-repeat: repeat;
}

.tag-box {
    width: 50%;
    height: 30px;
    padding-top: 50px;
    margin-right: 300px;
}

.tag-item {
    height: 30px;
    line-height: 30px;
    margin-right: 60px;
    color: #333;
    font-size: 20px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.item-line {
    width: 80px;
    height: 1px;
    background: #333;
    position: absolute;
    bottom: 3px;
    z-index: 100;
}


.navlink-active {
    text-decoration: none;
    color: inherit;
  }
   
  /* 如果你想完全去掉hover和active时的样式，可以这样做 */
  .navlink-active:hover,
  .navlink-active:active,
  .navlink-active:focus {
    color: inherit;
    text-decoration: none;
    outline: none;
  }