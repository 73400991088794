
.home-box {
    background-image: url('../../assets/home-content-bg.png');
    background-size: 100% 7215px;
    background-repeat: repeat;
}
.content {
    padding-top: 247px;
    padding-bottom: 300px;
}

.home-content {
    width: 1124px;
    height: 7215px;
    background-image: url('../../assets/home-content.png');
    background-size: 100% 7215px;
    background-repeat: repeat;
}