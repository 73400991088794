
.footer {
    padding-bottom: 60px;
}
.footer-company {
    font-size: 24px;
}
.footer-item {
    color: #999;
    font-size: 20px;
}
.footer-item-a {
    text-decoration: none;
}